import Axios from "axios";
const api = Axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/admin`,
  headers: {
    "x-api-key":
      "ZCCbKOUHrcU2HxmZgqft70ba4iy5lhJj6BLL5xcfOtRLZH5iQKzHoPSb44mBgxfT",
    "Content-Type": " application/json",
    Accept: "application/json",
    "Accept-Language": "ar",
  },
});

export default api;
