import axios from "./axios";
//auth
export const login = (data) =>
  axios({
    method: "post",
    url: `/login`,
    data: data,
  });

//medical center
export const getMedicalCenters = (page, filters) =>
  axios({
    method: "get",
    url: `/medical-centers`,
    params: {
      page,
      ...filters,
    },
  });
export const showMedicalCenter = (id) =>
  axios({
    method: "get",
    url: `/medical-centers/${id}`,
  });

export const createMedicalCenter = (data) =>
  axios({
    method: "post",
    url: `/medical-centers`,
    data,
  });

export const updateMedicalCenter = (id, data) =>
  axios({
    method: "put",
    url: `/medical-centers/${id}`,
    data,
  });
export const deleteMedicalCenterImage = (medical_id, id) =>
  axios({
    method: "delete",
    url: `medical-centers/${medical_id}/images/${id}`,
  });
export const deleteMedicalCenter = (id) =>
  axios({
    method: "delete",
    url: `/medical-centers/${id}`,
  });

//doctor
export const getDoctors = (page, medical_center_id, params) =>
  axios({
    method: "get",
    url: `/doctors`,
    params: {
      page,
      medical_center_id,
      ...params,
    },
  });
export const showDoctor = (id) =>
  axios({
    method: "get",
    url: `/doctors/${id}`,
  });

export const createDoctor = (data) =>
  axios({
    method: "post",
    url: `/doctors`,
    data,
  });

export const updateDoctor = (id, data) =>
  axios({
    method: "put",
    url: `/doctors/${id}`,
    data,
  });
export const updateDoctorFees = (id, data) =>
  axios({
    method: "put",
    url: `/doctors/${id}/update-fees`,
    data,
  });

export const deleteDoctor = (id) =>
  axios({
    method: "delete",
    url: `/doctors/${id}`,
  });

export const deleteClinicImage = (doctor_id, id) =>
  axios({
    method: "delete",
    url: `/doctors/${doctor_id}/gallery/${id}`,
  });

//featured doctors
export const getFeaturedDoctors = (page, params) =>
  axios({
    method: "get",
    url: `/featured-doctors`,
    params: {
      page,
      ...params,
    },
  });

export const addFeaturedDoctors = (data) =>
  axios({
    method: "post",
    url: `/doctors/add-featured-doctors`,
    data,
  });

export const deleteFeaturedDoctor = (id) =>
  axios({
    method: "delete",
    url: `/doctors/${id}/delete-featured-doctors`,
  });

//governorates
export const getGovernorates = (page, pagination) =>
  axios({
    method: "get",
    url: `/governorates`,
    params: {
      page,
      pagination,
    },
  });
export const showGovernorate = (id) =>
  axios({
    method: "get",
    url: `/governorates/${id}`,
  });

export const createGovernorate = (data) =>
  axios({
    method: "post",
    url: `/governorates`,
    data,
  });

export const updateGovernorate = (id, data) =>
  axios({
    method: "put",
    url: `/governorates/${id}`,
    data,
  });

export const deleteGovernorate = (id) =>
  axios({
    method: "delete",
    url: `/governorates/${id}`,
  });

//cities
export const getCities = (page, governorate_id, pagination) =>
  axios({
    method: "get",
    url: `/cities`,
    params: {
      page,
      governorate_id,
      pagination,
    },
  });
export const showCity = (id) =>
  axios({
    method: "get",
    url: `/cities/${id}`,
  });

export const createCity = (data) =>
  axios({
    method: "post",
    url: `/cities`,
    data,
  });

export const updateCity = (id, data) =>
  axios({
    method: "put",
    url: `/cities/${id}`,
    data,
  });

export const deleteCity = (id) =>
  axios({
    method: "delete",
    url: `/cities/${id}`,
  });

// insurance-companies
export const getCompanies = (page, pagination) =>
  axios({
    method: "get",
    url: `/insurance-companies`,
    params: {
      page,
      pagination,
    },
  });
export const showCompany = (id) =>
  axios({
    method: "get",
    url: `/insurance-companies/${id}`,
  });

export const createCompany = (data) =>
  axios({
    method: "post",
    url: `/insurance-companies`,
    data,
  });

export const updateCompany = (id, data) =>
  axios({
    method: "put",
    url: `/insurance-companies/${id}`,
    data,
  });

export const deleteCompany = (id) =>
  axios({
    method: "delete",
    url: `/insurance-companies/${id}`,
  });

// professional-titles
export const getProfessionalTitles = (page, pagination) =>
  axios({
    method: "get",
    url: `/professional-titles`,
    params: {
      page,
      pagination,
    },
  });
export const showProfessionalTitle = (id) =>
  axios({
    method: "get",
    url: `/professional-titles/${id}`,
  });
export const createProfessionalTitle = (data) =>
  axios({
    method: "post",
    url: `/professional-titles`,
    data,
  });
export const updateProfessionalTitle = (id, data) =>
  axios({
    method: "put",
    url: `/professional-titles/${id}`,
    data,
  });
export const deleteProfessionalTitle = (id) =>
  axios({
    method: "delete",
    url: `/professional-titles/${id}`,
  });

// titles
export const getTitles = (page) =>
  axios({
    method: "get",
    url: `/titles`,
    params: {
      page,
    },
  });
export const showTitle = (id) =>
  axios({
    method: "get",
    url: `/titles/${id}`,
  });
export const createTitle = (data) =>
  axios({
    method: "post",
    url: `/titles`,
    data,
  });
export const updateTitle = (id, data) =>
  axios({
    method: "put",
    url: `/titles/${id}`,
    data,
  });
export const deleteTitle = (id) =>
  axios({
    method: "delete",
    url: `/titles/${id}`,
  });

// languages
export const getLanguages = (page, pagination) =>
  axios({
    method: "get",
    url: `/languages`,
    params: {
      page,
      pagination,
    },
  });
export const showLanguage = (id) =>
  axios({
    method: "get",
    url: `/languages/${id}`,
  });
export const createLanguage = (data) =>
  axios({
    method: "post",
    url: `/languages`,
    data,
  });
export const updateLanguage = (id, data) =>
  axios({
    method: "put",
    url: `/languages/${id}`,
    data,
  });
export const deleteLanguage = (id) =>
  axios({
    method: "delete",
    url: `/languages/${id}`,
  });

// medical-specialties
export const getMedicalSpecialties = (pagination) =>
  axios({
    method: "get",
    url: `/medical-specialties`,
    params: {
      pagination,
    },
  });

export const createMedicalSpecialties = (data) =>
  axios({
    method: "post",
    url: `/medical-specialties`,
    data,
  });

export const showMedicalSpecialties = (id) =>
  axios({
    method: "get",
    url: `/medical-specialties/${id}`,
  });

export const updateMedicalSpecialties = (id, data) =>
  axios({
    method: "put",
    url: `/medical-specialties/${id}`,
    data,
  });
export const delteMedicalSpecialties = (id) =>
  axios({
    method: "delete",
    url: `/medical-specialties/${id}`,
  });

// lab service
export const getLabSpecialties = (page, pagination) =>
  axios({
    method: "get",
    url: `/lab-specialties`,
    params: {
      page,
      pagination,
    },
  });

export const createLabService = (data) =>
  axios({
    method: "post",
    url: "/lab-specialties",
    data,
  });

export const updateLabService = (id, data) =>
  axios({
    method: "put",
    url: `/lab-specialties/${id}`,
    data,
  });

export const deleteLabService = (id) =>
  axios({
    method: "delete",
    url: `/lab-specialties/${id}`,
  });

// home nursing
export const getHomeNursingList = (page, pagination) =>
  axios({
    method: "get",
    url: `/nursing-specialties`,
    params: {
      page,
      pagination,
    },
  });

export const createHomeNursing = (data) =>
  axios({
    method: "post",
    url: "/nursing-specialties",
    data,
  });

export const updateHomeNursing = (id, data) =>
  axios({
    method: "put",
    url: `/nursing-specialties/${id}`,
    data,
  });

export const deleteHomeNursing = (id) =>
  axios({
    method: "delete",
    url: `/nursing-specialties/${id}`,
  });

// offer specialties
export const getOffersSpecialtiesList = (page, pagination) =>
  axios({
    method: "get",
    url: `/offer-specialties`,
    params: {
      page,
      pagination,
    },
  });

export const createOffersSpecialties = (data) =>
  axios({
    method: "post",
    url: "/offer-specialties",
    data,
  });

export const updateOffersSpecialties = (id, data) =>
  axios({
    method: "put",
    url: `/offer-specialties/${id}`,
    data,
  });

export const deleteOffersSpecialties = (id) =>
  axios({
    method: "delete",
    url: `/offer-specialties/${id}`,
  });

// home vaccination
export const getHomeVaccinationList = (page, pagination) =>
  axios({
    method: "get",
    url: `/vaccinations`,
    params: {
      page,
      pagination,
    },
  });

export const createHomeVaccination = (data) =>
  axios({
    method: "post",
    url: "/vaccinations",
    data,
  });

export const updateHomeVaccination = (id, data) =>
  axios({
    method: "put",
    url: `/vaccinations/${id}`,
    data,
  });

export const deleteHomeVaccination = (id) =>
  axios({
    method: "delete",
    url: `/vaccinations/${id}`,
  });

// sub specialties
export const getSubSpecialties = (page, medical_specialty_id, pagination) =>
  axios({
    method: "get",
    url: `/sub-medical-specialties`,
    params: {
      page,
      medical_specialty_id,
      pagination,
    },
  });

export const createSubService = (data) =>
  axios({
    method: "post",
    url: "/sub-medical-specialties",
    data,
  });

export const updateSubService = (id, data) =>
  axios({
    method: "put",
    url: `/sub-medical-specialties/${id}`,
    data,
  });

export const deleteSubService = (id) =>
  axios({
    method: "delete",
    url: `/sub-medical-specialties/${id}`,
  });

// doctor-appointments
export const getAppointments = (doctor_id) =>
  axios({
    method: "get",
    url: `/doctor-appointments`,
    params: {
      doctor_id,
    },
  });
export const showAppointment = (id) =>
  axios({
    method: "get",
    url: `/doctor-appointments/${id}`,
  });
export const createAppointment = (data) =>
  axios({
    method: "post",
    url: `/doctor-appointments`,
    data,
  });

export const deleteAppointment = (id) =>
  axios({
    method: "delete",
    url: `/doctor-appointments/${id}`,
  });

export const createMultipleAppointments = (data) =>
  axios({
    method: "post",
    url: `/doctor-appointments/store-bulk-array`,
    data,
  });

export const getReservations = (page, date, doctor_id, m_center_id) =>
  axios({
    method: "get",
    url: `/doctor-reservations`,
    params: {
      page,
      date,
      doctor_id,
      m_center_id,
    },
  });

export const showReservation = (id) =>
  axios({
    method: "get",
    url: `/doctor-reservations/${id}`,
  });
export const updateReservationAttendance = (id, data) =>
  axios({
    method: "put",
    url: `/doctor-reservations/${id}/update-status`,
    data,
  });

//labs
export const getLabs = (page, filters) =>
  axios({
    method: "get",
    url: `/labs`,
    params: {
      page,
      ...filters,
    },
  });
export const showLab = (id) =>
  axios({
    method: "get",
    url: `/labs/${id}`,
  });
export const createLab = (data) =>
  axios({
    method: "post",
    url: `/labs`,
    data,
  });
export const updateLab = (id, data) =>
  axios({
    method: "put",
    url: `/labs/${id}`,
    data,
  });
export const deleteLab = (id) =>
  axios({
    method: "delete",
    url: `/labs/${id}`,
  });
export const deleteLabImage = (medical_id, id) =>
  axios({
    method: "delete",
    url: `labs/${medical_id}/images/${id}`,
  });

//lab tests
export const getLabTests = (lab_id, page, params) =>
  axios({
    method: "get",
    url: `/labs/${lab_id}/analyses`,
    params: {
      page,
      ...params,
    },
  });
export const createLabTest = (lab_id, data) =>
  axios({
    method: "post",
    url: `/labs/${lab_id}/analyses`,
    data,
  });
export const updateLabTest = (lab_id, id, data) =>
  axios({
    method: "put",
    url: `/labs/${lab_id}/analyses/${id}`,
    data,
  });
export const deleteLabTest = (lab_id, id) =>
  axios({
    method: "delete",
    url: `/labs/${lab_id}/analyses/${id}`,
  });

// lab orders
export const getLabOrders = (lab_id, page, date, status) =>
  axios({
    method: "get",
    url: `/labs/${lab_id}/lab-orders`,
    params: {
      page,
      // date,
      status,
    },
  });
export const showLabOrder = (lab_id, id) =>
  axios({
    method: "get",
    url: `/labs/${lab_id}/lab-orders/${id}`,
  });

//patients
export const getPatients = (page, filters) =>
  axios({
    method: "get",
    url: `/patients`,
    params: {
      page,
      ...filters,
    },
  });

//statistics
export const getStatistics = (medical_center_id) =>
  axios({
    method: "get",
    url: `/statistics`,
    params: {
      medical_center_id,
    },
  });

//support messages
export const getSupportMessages = () =>
  axios({
    method: "get",
    url: `/support-messages`,
  });

export const getSupportMessage = (id) =>
  axios({
    method: "get",
    url: `/support-messages/${id}`,
  });

export const deleteSupportMessage = (id) =>
  axios({
    method: "delete",
    url: `/support-messages/${id}`,
  });

//offers
export const getOffers = (page, { admin_status, owner_type, owner_id }) =>
  axios({
    method: "get",
    url: `/offers`,
    params: {
      page,
      admin_status,
      owner_type: owner_type.replace("-", "_"),
      owner_id,
    },
  });
export const showOffer = (id) =>
  axios({
    method: "get",
    url: `/offers/${id}`,
  });

export const createOffer = (data) =>
  axios({
    method: "post",
    url: `/offers`,
    data,
  });

export const updateOffer = (id, data) =>
  axios({
    method: "put",
    url: `/offers/${id}`,
    data,
  });
export const deleteOffer = (id) =>
  axios({
    method: "delete",
    url: `/offers/${id}`,
  });

export const deleteOfferImage = (offer_id, id) =>
  axios({
    method: "delete",
    url: `/offers/${offer_id}/gallery/${id}`,
  });

export const updateOfferStatus = (id, data) =>
  axios({
    method: "post",
    url: `/offers/${id}/admin-status`,
    data,
  });

export const getDiscount = (page) =>
  axios({
    method: "get",
    url: `/offer-forms`,
    params: {
      page,
    },
  });

//urgent requests
export const getRequests = (page, status) =>
  axios({
    method: "get",
    url: `/urgent-requests`,
    params: {
      page,
      status,
    },
  });

// support doctors
export const getSupportDoctors = (page) =>
  axios({
    method: "get",
    url: `/support-doctors`,
    params: {
      page,
    },
  });
export const showSupportDoctor = (id) =>
  axios({
    method: "get",
    url: `/support-doctors/${id}`,
  });

export const createSupportDoctor = (data) =>
  axios({
    method: "post",
    url: `/support-doctors`,
    data,
  });

export const updateSupportDoctor = (id, data) =>
  axios({
    method: "put",
    url: `/support-doctors/${id}`,
    data,
  });

export const deleteSupportDoctor = (id) =>
  axios({
    method: "delete",
    url: `/support-doctors/${id}`,
  });

// previous urgent calls
export const getPreviousCalls = (page) =>
  axios({
    method: "get",
    url: `/urgent-callings`,
    params: {
      page,
    },
  });
export const showPreviousCall = (id) =>
  axios({
    method: "get",
    url: `/urgent-callings/${id}`,
  });

// crm clients
export const getCrmClients = (page) =>
  axios({
    method: "get",
    url: `/crm-clients`,
    params: {
      page,
    },
  });
export const showCrmClient = (id) =>
  axios({
    method: "get",
    url: `/crm-clients/${id}`,
  });

export const createCrmClient = (data) =>
  axios({
    method: "post",
    url: `/crm-clients`,
    data,
  });

export const updateCrmClient = (id, data) =>
  axios({
    method: "put",
    url: `/crm-clients/${id}`,
    data,
  });

export const deleteCrmClient = (id) =>
  axios({
    method: "delete",
    url: `/crm-clients/${id}`,
  });
