import { LOGIN, LOGOUT } from "./types";
import { Show422ErrorMessage, nthIndex } from "../../helperFunctions/functions";
import { setStorage } from "../../helperComponents/locale-storage";
import { login } from "../../api";
import { message } from "antd";

export const loginAction = (data, props) => (dispatch) => {
  return login(data)
    .then((res) => {
      if (res.data.is_super_admin) {
        dispatch({
          type: LOGIN,
          payload: {
            ...res.data,
            user_info: {
              ...res.data.data,
              type: "default",
            },
          },
        });

        setStorage("login_token", res.data.token);
        setStorage("is_logged", "true");
        setStorage(
          "user_info",
          JSON.stringify({
            ...res.data.data,
            type: "default",
          })
        );

        if (props.match.params.redirect) {
          props.history.push(
            `/${props.location.pathname.substring(
              nthIndex(props.location.pathname, "login/", 1) + 6,
              props.location.pathname.length
            )}${props.location.search}`
          );
        } else {
          props.history.push(`/`);
        }
      } else {
        message("Only admins can login to the dashboard");
      }
      console.log("RESPONSE RECEIVED: ", res);
      return res;
    })
    .catch((err) => {
      if (err.response && err.response.status === 422) {
        Show422ErrorMessage(err.response.data.errors);
        console.log("AXIOS ERROR: ", err.response);
      }
      console.log("AXIOS ERROR: ", err);
      return Promise.reject(err);
    });
};

export const logout = () => (dispatch) => {
  localStorage.clear();

  dispatch({
    type: LOGOUT,
  });
};
