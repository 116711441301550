import { LOGIN, LOGOUT } from "../actions/types";
import {
  getStorage,
  checkStorage,
  setStorage,
} from "../../helperComponents/locale-storage";
const initial_state = {
  login_token: checkStorage("login_token") ? getStorage("login_token") : "",
  is_logged: checkStorage("is_logged") ? getStorage("is_logged") : "false",

  web_view: checkStorage("web_view") ? getStorage("web_view") : "false",
  user_info: checkStorage("user_info")
    ? JSON.parse(getStorage("user_info"))
    : { type: "default" },
};

export default function (state = initial_state, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        login_token: action.payload.token,
        // type: action.payload.type,
        is_logged: "true",
        user_info: action.payload.user_info,
      };
    case LOGOUT:
      return {
        ...state,
        login_token: "",
        type: "",
        is_logged: "false",
        user_info: {},
      };

    default:
      return state;
  }
}
