import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import "react-phone-input-2/lib/style.css";
import "antd/dist/antd.css";
// import sass
import "../sass/app.scss";

//redux
import { Provider, connect } from "react-redux";
import store from "./redux/store";
import { resizeWindow } from "./redux/actions/layoutActions";
import { logout } from "./redux/actions/authActions";

import { nthIndex } from "./helperFunctions/functions";
import { deleteStorage } from "./helperComponents/locale-storage";

import { Show422ErrorMessage } from "./helperFunctions/functions";
import axios from "./api/axios";
import { notification, message, ConfigProvider } from "antd";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center font-Xlarge text-theme-color">
    <LoadingOutlined />
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout"));

// Pages
const Login = React.lazy(() => import("./views/login"));

class Main extends Component {
  componentDidUpdate(props) {
    var hours = 1; // Reset when last action is more than an hour
    var now = new Date().getTime();
    var setupTime = parseInt(localStorage.getItem("setupTime"));

    if (setupTime === null) {
      props.logout();
      localStorage.setItem("setupTime", now);
    } else {
      if (now - setupTime > hours * 60 * 60 * 1000) {
        deleteStorage("setupTime");
        props.logout();
      } else {
        localStorage.setItem("setupTime", now);
      }
    }
  }

  componentDidMount() {
    window.onresize = () => {
      this.props.resizeWindow(window.innerWidth);
    };

    axios.interceptors.response.use(
      (res) => {
        if (res.data.message) {
          message.destroy();
          message.success(res.data.message);
        }
        return res;
      },
      (error) => {
        var serverError = false;
        notification.destroy();
        message.destroy();
        if (error.response) {
          if (error.response.status === 401) {
            this.props.logout();
          }
          if (error.response.status) {
            if (error.response.status === 422) {
              Show422ErrorMessage(error.response.data.errors);
            } else if (error.response.status === 400) {
              message.error(error.response.data.message);
              console.log("response", error.response.data.message);
            } else if (error.response.status === 403) {
              notification.error({
                message: "Forbidden ",
              });
            } else if (error.response.status === 405) {
              notification.error({
                message: "Not Allowed",
              });
            } else if (error.response.status === 429) {
              notification.error({
                message: "Too Many Request",
              });
            } else if (error.response.status === 401) {
              notification.error({
                message: "Unauthorized",
              });
            } else if (error.response.status === 500) {
              notification.error({
                message: "Server Error",
              });
            } else if (error.response.status === 503) {
              notification.error({
                message: "Service Unavailable",
              });
            } else if (error.response.status === 404) {
              notification.error({
                message: "Not Found",
              });
            } else {
              notification.error({
                message: "Server Error",
              });
            }
          }
        } else {
          serverError = true;
        }
        if (serverError) {
          notification.error({
            message: "Connection Error",
          });
        }
        return Promise.reject(error);
      }
    );
  }

  render() {
    const { is_logged, login_token } = this.props;
    if (login_token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${login_token}`;
    }
    document.body.classList.add("rtl");
    return (
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route
            path="/login/:redirect?"
            render={(props) =>
              is_logged !== "true" ? (
                <React.Fragment>
                  <Login {...props} />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Redirect to={`/`} />
                </React.Fragment>
              )
            }
          />

          <Route
            path={`/`}
            render={(props) =>
              is_logged === "true" ? (
                <React.Fragment>
                  <Route
                    path="/"
                    name="Home"
                    render={(props) => (
                      <React.Fragment>
                        <DefaultLayout {...props} />
                      </React.Fragment>
                    )}
                  />
                </React.Fragment>
              ) : (
                <Redirect
                  to={`/login/${props.location.pathname.substring(
                    nthIndex(props.location.pathname, "/", 1) + 1,
                    props.location.pathname.length
                  )}${props.location.search}`}
                />
              )
            }
          />
        </Switch>
      </React.Suspense>
    );
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const mapStateToProps = (state) => ({
      login_token: state.auth.login_token,
      is_logged: state.auth.is_logged,
    });
    const MainCompenent = connect(mapStateToProps, { resizeWindow, logout })(
      Main
    );
    return (
      <React.Fragment>
        <Provider store={store}>
          <ConfigProvider direction="rtl">
            <Router>
              <Route
                path="/"
                render={(props) => <MainCompenent {...props} />}
              />
            </Router>
          </ConfigProvider>
        </Provider>
      </React.Fragment>
    );
  }
}

export default App;
